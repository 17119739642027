<template>
  <div class="ui container">
    <div class="ui menu">
      <menu-item
        v-if="!editing"
        :action="edit"
        classes="primary"
        icon="edit"
        label="Edit"
      ></menu-item>

      <menu-item v-if="!editing && $route.params.id !== 'new' && !businessUser.is_active"
        :action="enable"
        :loading="loading.enable"
        classes="positive basic"
        icon="add"
        label="Enable"
      ></menu-item>

      <menu-item v-if="!editing && $route.params.id !== 'new' && businessUser.is_active"
        :action="disable"
        :loading="loading.disable"
        classes="negative basic"
        icon="remove"
        label="Disable"
      ></menu-item>

      <menu-item
        v-if="editing"
        :action="submit"
        :loading="loading.submit"
        classes="primary"
        icon="mail"
        label="Submit"
      ></menu-item>

      <menu-item
        v-if="editing"
        :action="discard"
        classes="secondary basic"
        icon="undo"
        label="Discard"
      ></menu-item>
    </div>

    <div class="ui raised segment">
      <div class="ui text container">
        <div
          class="ui active centered inline loader"
          v-if="loading.businessUser"
        ></div>

        <h1>{{ header }} <span v-if="!businessUser.is_active">(Disabled)</span></h1>
        <div id="businessUserForm" class="ui equal width form">
          <div class="ui error message"></div>
          <div class="field">
            <label>Email Address</label>
            <input
              v-if="editing"
              v-model="businessUser.user.email"
              placeholder="email@example.com"
              name="email"
              :disabled="$route.params.id !== 'new'"
            />
            <p v-else>{{ businessUser.user?.email }}</p>
          </div>
          <div class="field">
            <label>Role</label>
            <select
              v-if="editing"
              v-model="businessUser.role"
              class="ui fluid dropdown"
            >
              <option value="A">Administrator</option>
              <option value="S">Customer Support</option>
              <option value="R">Sales Rep</option>
            </select>
            <p v-else>
              {{ ROLES[businessUser.role] }}
            </p>
          </div>
          <template v-if="businessUser.role === 'A'">
            <div class="field">
              <div class="ui checkbox">
                <input
                  v-model="businessUser.receive_all_proofs"
                  type="checkbox"
                  tabindex="0"
                  class="hidden"
                  :disabled="!editing"
                />
                <label>Receives all proofs</label>
              </div>
            </div>
            <div class="field">
              <div class="ui checkbox">
                <input
                  v-model="businessUser.receive_invoices"
                  type="checkbox"
                  tabindex="0"
                  class="hidden"
                  :disabled="!editing"
                />
                <label>Receives invoices</label>
              </div>
            </div>
          </template>

          <div
            v-if="businessUser.role === 'R' && editing"
            id="managed_by"
            name="managed_by"
            class="ui fluid multiple search selection dropdown"
          >
            <input type="hidden" name="managed_by_input" />
            <i class="dropdown icon"></i>
            <div class="default text">Managed by</div>
            <div class="menu">
              <div
                class="item"
                v-for="user in users"
                :key="user.id"
                :data-value="user.id"
              >
                {{ user.user.email }}
              </div>
            </div>
          </div>

          <div class="field" v-if="!editing && businessUser.role === 'R'">
            <label>Managed by</label>
            <span
              v-for="userId in businessUser.managed_by"
              :key="userId"
              class="ui label"
            >
              {{ userFromId(userId)?.user.email }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formErrorHandler from "@/utils/formErrorHandler.js";
import MenuItem from "@/components/MenuItem.vue";
import { mapState } from "vuex";

const $ = window.jQuery;

export default {
  components: {
    MenuItem
  },

  data() {
    return {
      users: [],
      ROLES: {
        A: "Administrator",
        S: "Customer Support",
        R: "Sales Rep"
      },

      editing: true,
      header: "",
      businessUser: {
        managed_by: [],
        user: {
          email: null
        },
        role: "R"
      },

      loading: {
        businessUser: false,
        disable: false,
        enable: false,
        submit: false
      }
    };
  },

  computed: {
    isLoading() {
      return Object.keys(this.loading).reduce(
        (acc, v) => acc || this.loading[v],
        false
      );
    },

    ...mapState(["currentBusinessId"])
  },

  methods: {
    userFromId(userId) {
      return this.users.find(u => u.id === userId);
    },

    edit() {
      this.editing = true;
      this.$nextTick(() => {
        $('.ui.checkbox').checkbox();
        $(".ui.dropdown").dropdown("set selected", this.businessUser.managed_by);
      })
    },

    enable () {
      if (window.confirm("Are you sure you want to enable this user?")) {
        this.loading.enable = true
        this.$http.patch('api/v1/businessusers/' + this.businessUser.id + '/enable/').then(() => {
          window.location.reload();
        }).catch((error) => {
          this.loading.enable = false
          formErrorHandler("businessUserForm", error);
        })
      }
    },

    disable () {
      if (window.confirm("Are you sure you want to disable this user?")) {
        this.loading.disable = true
        this.$http.patch('api/v1/businessusers/' + this.businessUser.id + '/disable/').then(() => {
          window.location.reload();
        }).catch((error) => {
          this.loading.disable = false
          formErrorHandler("businessUserForm", error);
        })
      }
    },

    discard() {
      if (window.confirm("Discard all changes?")) {
        if (this.$route.params.id === "new") {
          this.$router.push({ name: "BusinessUsers" });
        } else {
          window.location.reload();
        }
      }
    },

    async submit() {
      if (this.$route.params.id === "new") {
        let payload = {
          business: this.currentBusinessId,
          email: this.businessUser.user.email,
          role: this.businessUser.role
          // password: Math.floor(Math.random() * 10000000000)
          //   .toString()
          //   .padStart("10", "0")
        };

        if (!payload.email) {
          alert("User must have an email address.");
          return;
        }

        if (
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            payload.email
          )
        ) {
          alert("Email address doesn't appear to be valid.");
          return;
        }

        if (this.businessUser.role === "R") {
          const managed_by_string = $("#managed_by").dropdown("get value");

          if (managed_by_string) {
            payload.managed_by = managed_by_string.split(",");
          } else {
            alert("Sales Reps need to have at least one manager.");
            return;
          }
        } else if (this.businessUser.role === "A") {
          payload.receive_all_proofs = this.businessUser.receive_all_proofs;
          payload.receive_invoices = this.businessUser.receive_invoices;
        }

        this.loading.submit = true;
        try {
          await this.$http.post("api/v1/businessusers/", payload);
          this.$router.push({ name: "BusinessUsers" });
        } catch (error) {
          this.loading.submit = false;
          formErrorHandler("businessUserForm", error);
        }
      } else {
        let payload = {
          business: this.currentBusinessId,
          role: this.businessUser.role
        };

        if (this.businessUser.role === "R") {
          payload.receive_all_proofs = false;
          payload.receive_invoices = false;
          const managed_by_string = $("#managed_by").dropdown("get value");

          if (managed_by_string) {
            payload.managed_by = managed_by_string.split(",");
          } else {
            alert("Sales Reps need to have at least one manager.");
            return;
          }
        } else if (this.businessUser.role === "A") {
          payload.receive_all_proofs = this.businessUser.receive_all_proofs;
          payload.receive_invoices = this.businessUser.receive_invoices;
        }

        this.loading.submit = true;
        try {
          await this.$http.patch(`api/v1/businessusers/${this.businessUser.id}/`, payload);
          location.reload();
        } catch (error) {
          this.loading.submit = false;
          formErrorHandler("businessUserForm", error);
        }
      }
    }
  },

  watch: {
    "businessUser.role"(role) {
      if (role === "R" && this.editing) {
        this.$nextTick(() => {
          $(".ui.dropdown").dropdown();
        });
      } else if (role === "A" && this.editing) {
        this.$nextTick(() => {
          $(".ui.checkbox").checkbox();
        });
      }
    }
  },

  async created() {
    if (this.$route.params.id !== "new") {
      this.editing = false;
      this.loading.businessUser = true;
      let usersResponse = null;
      try {
        usersResponse = await this.$http.get(
          `api/v1/businesses/${this.currentBusinessId}/users/?page_size=1000`
        );
      } catch (error) {
        alert(error);
      } finally {
        this.loading.businessUser = false;
      }
      this.users = usersResponse.data.results;

      this.businessUser = this.users.find(u => u.id === this.$route.params.id);
      this.header = this.businessUser.user.email;
    } else {
      this.header = "New User";
      let usersResponse = null;
      try {
        usersResponse = await this.$http.get(
          `api/v1/businesses/${this.currentBusinessId}/users/?page_size=1000`
        );
      } catch (error) {
        alert(error);
      } finally {
        this.loading.businessUser = false;
      }
      this.users = usersResponse.data.results;
      this.$nextTick(() => {
        $(".ui.dropdown").dropdown();
      });
    }
  }
};
</script>
